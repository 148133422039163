import React, { useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useConnectWallet } from '@web3-onboard/react';
import { WalletState } from '@web3-onboard/core';
import { ethers } from 'ethers';
import abi from 'erc-20-abi';
import { CryptoCurrency } from '@/api/common.types';
import { Button } from '@/components/common';
import { showNotification } from '@/utils/showNotification';
import { useRouter } from 'next/router';
import { Routes } from '@/constants/routes';
import { useUpdateSellTxs } from '@/api/queryHooks/useSellCryptoController';
import { useUpdateMerchantTxn } from '@/api/queryHooks/useMerchantCryptoController';
import { useGetCoinNetworks } from '@/api';

export function normalizeGasLimit(gasLimit: number) {
  const DEFAULT_GAS_BUFFER = 15000;

  return gasLimit + DEFAULT_GAS_BUFFER;
}

export type OnConnectWallet = WalletState | null;

interface IConnectWallet {
  onConnect?: (w?: OnConnectWallet) => void;
  isMerchant?: boolean;
  paymentOptions?: {
    depositAddress?: string;
    cryptoAmount?: number;
    cryptoCurrency?: CryptoCurrency;
    selectedNetwork?: string;
    transactionId?: string;
  };
  selectedNetworkContractAddress?: string | null;
}

export interface IConnectWalletRef {
  connect: () => Promise<WalletState[]>;
  disconnect: () => Promise<WalletState[] | null>;
  wallet: WalletState | null;
}

const ConnectWalletBtn = React.forwardRef<IConnectWalletRef, IConnectWallet>(
  ({ onConnect, paymentOptions, isMerchant, selectedNetworkContractAddress }, ref) => {
    const router = useRouter();
    const [{ wallet, connecting }, connectLocal, disconnectLocal] = useConnectWallet();
    const [ethersProvider, setProvider] = useState<ethers.providers.Web3Provider | null>(null);
    const { mutate: updateSellTxs } = useUpdateSellTxs();
    const { mutate: updateMerchantTxs } = useUpdateMerchantTxn();

    useImperativeHandle(
      ref,
      () => ({
        connect: async () => {
          const wallets = await connectLocal();
          const NEXTContainer = document.querySelector('#__next') as HTMLElement;
          if (NEXTContainer) {
            NEXTContainer.style.filter = '';
          }
          return wallets;
        },
        disconnect: async () => {
          if (wallet) {
            return disconnectLocal(wallet);
          }
          return null;
        },
        wallet,
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [wallet],
    );

    useEffect(() => {
      router.events.on('routeChangeStart', (url) => {
        if ((url === Routes.SIGN_IN || url === Routes.SIGN_UP) && wallet) {
          disconnectLocal(wallet);
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wallet]);

    useEffect(() => {
      // const chainId = wallet?.chains?.[0].id || undefined;

      if (wallet?.provider) {
        setProvider(new ethers.providers.Web3Provider(wallet.provider, 'any'));
      }

      if (wallet) {
        onConnect?.(wallet);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wallet]);

    if (paymentOptions && wallet) {
      return (
        <Button
          type="button"
          size="small"
          iconPosition="right"
          icon="externalLink"
          onPress={async () => {
            if (
              !paymentOptions?.cryptoCurrency ||
              !paymentOptions?.depositAddress ||
              !paymentOptions.cryptoAmount ||
              !paymentOptions.selectedNetwork
            ) {
              return;
            }
            try {
              const signer = await ethersProvider?.getSigner();
              if (
                paymentOptions.cryptoCurrency === CryptoCurrency.ETH &&
                paymentOptions.selectedNetwork === CryptoCurrency.ETH
              ) {
                const value = ethers.utils.parseEther(paymentOptions.cryptoAmount.toString());
                const tx = await signer?.sendTransaction({ to: paymentOptions.depositAddress, value });

                if (!tx?.hash || !paymentOptions.transactionId) {
                  showNotification();
                  return null;
                }

                if (isMerchant) {
                  updateMerchantTxs({
                    transactionId: paymentOptions.transactionId,
                    sourceAddress: wallet?.accounts[0].address,
                    blockchainHash: tx.hash,
                  });
                } else {
                  updateSellTxs({
                    transactionId: paymentOptions.transactionId,
                    sourceAddress: wallet?.accounts[0].address,
                    blockchainHash: tx.hash,
                  });
                }
              } else {
                if (!selectedNetworkContractAddress || selectedNetworkContractAddress?.length === 0) {
                  showNotification('Something went wrong. Please contact support');
                  // eslint-disable-next-line no-console
                  console.error('Invalid network contract address');
                  return;
                }

                const erc20 = new ethers.Contract(selectedNetworkContractAddress, abi, signer);

                const senderBalance = await erc20.balanceOf(signer?.getAddress());

                const decimals = await erc20.decimals();
                const amount = ethers.utils.parseUnits(paymentOptions.cryptoAmount.toString(), decimals);

                if (amount.gt(senderBalance)) {
                  // Handle insufficient balance, e.g., show an error message to the user.
                  showNotification('Insufficient balance for the transfer.');
                  return;
                }

                if (signer) {
                  const tokenSigner = erc20.connect(signer);
                  const gasPrice = await ethersProvider?.getGasPrice();
                  const gasLimit = await tokenSigner.estimateGas.transfer(paymentOptions.depositAddress, amount);
                  const normalizedGasLimit = normalizeGasLimit(gasLimit.toNumber());

                  const tx = await tokenSigner.transfer(paymentOptions.depositAddress, amount, {
                    gasPrice,
                    gasLimit: normalizedGasLimit,
                  });

                  if (!tx?.hash || !paymentOptions.transactionId) {
                    showNotification();
                    return null;
                  }
                  if (isMerchant) {
                    updateMerchantTxs({
                      transactionId: paymentOptions.transactionId,
                      sourceAddress: wallet?.accounts[0].address,
                      blockchainHash: tx.hash,
                    });
                  } else {
                    updateSellTxs({
                      transactionId: paymentOptions.transactionId,
                      sourceAddress: wallet?.accounts[0].address,
                      blockchainHash: tx.hash,
                    });
                  }
                }
              }
            } catch (e: any) {
              if (e.message.includes('INSUFFICIENT_FUNDS')) {
                showNotification('Insufficient funds');
              } else {
                showNotification();
                // eslint-disable-next-line no-console
                console.error(e?.message ?? '');
              }
            }
          }}
        >
          Pay now
        </Button>
      );
    }

    return null;
  },
);
ConnectWalletBtn.displayName = 'ConnectWalletBtn';

export const ConnectWallet = React.forwardRef<IConnectWalletRef, IConnectWallet>(
  ({ onConnect, paymentOptions, isMerchant = false }, ref) => {
    const { data } = useGetCoinNetworks([
      { cryptoCurrency: paymentOptions?.cryptoCurrency || '' },
      { enabled: !!paymentOptions?.cryptoCurrency },
    ]);

    const selectedNetworkContractAddress = useMemo(
      () => data?.filter((item) => item?.cryptoNetwork === paymentOptions?.selectedNetwork)?.[0]?.contractAddress,
      [data, paymentOptions?.selectedNetwork],
    );
    return (
      <ConnectWalletBtn
        isMerchant={isMerchant}
        onConnect={onConnect}
        paymentOptions={paymentOptions}
        ref={ref}
        selectedNetworkContractAddress={selectedNetworkContractAddress}
      />
    );
  },
);
ConnectWallet.displayName = 'ConnectWallet';
