import { Icon } from '../../Icon';
import styles from './TableEmptyState.module.css';

export const TableEmptyState = () => {
  return (
    <div className={styles.container}>
      <Icon name="search" size={48} className={styles.icon} />
      <p className={styles.description}>There is no results.</p>
    </div>
  );
};
