import clsx from 'clsx';
import React from 'react';
import { Icon } from '../../Icon';
import styles from './TableHeadCell.module.css';

export interface TableHeadCellProps {
  children: string;
  className?: string;
  textClassName?: string;
  iconClassName?: string;
  sort?: boolean;
  active?: boolean;
  isDesc?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const TableHeadCell = ({
  children,
  sort = true,
  active,
  isDesc,
  onClick,
  className,
  textClassName,
  iconClassName,
}: TableHeadCellProps) => {
  return (
    <button
      onClick={onClick}
      className={clsx(styles.col, className, {
        [styles.active]: active,
      })}
      type="button"
    >
      {!!sort && (
        <>
          {active ? (
            <Icon className={clsx(styles.icon, iconClassName)} name={isDesc ? 'arrowDown' : 'arrowTop'} />
          ) : (
            <Icon className={clsx(styles.icon, iconClassName)} name="sort" />
          )}
        </>
      )}
      <span className={clsx(styles.text, textClassName)}>{children}</span>
    </button>
  );
};
