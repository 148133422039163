import { useEffect, useState } from 'react';
import { CryptoCurrency } from '@/api/common.types';
import { useGetSellTxn } from '@/api/queryHooks/useSellCryptoController';
import { useGetBuyTxn } from '@/api/queryHooks/useBuyCryptoController';

export function useGetReplacedTxInfo(isBuy?: boolean) {
  const [replacedTxId, setReplacedTxId] = useState('');
  const [replacedTxInfo, setReplacedTxInfo] =
    useState<{ cryptoCurrency: CryptoCurrency; blockchainTxHash: string } | null>(null);

  const { refetch: getReplacedSellTxn } = useGetSellTxn([
    { id: replacedTxId },
    {
      enabled: false,
      onSuccess(data) {
        if (data.cryptoCurrency && data.blockchainTxHash) {
          setReplacedTxInfo({ cryptoCurrency: data.cryptoCurrency, blockchainTxHash: data.blockchainTxHash });
        }
      },
    },
  ]);

  const { refetch: getReplacedBuyTxn } = useGetBuyTxn([
    { id: replacedTxId },
    {
      enabled: false,
      onSuccess(data) {
        if (data.cryptoCurrency && data.blockchainTxHash) {
          setReplacedTxInfo({ cryptoCurrency: data.cryptoCurrency, blockchainTxHash: data.blockchainTxHash });
        }
      },
    },
  ]);

  useEffect(() => {
    if (!replacedTxId) return;

    if (isBuy) {
      getReplacedBuyTxn();
    } else {
      getReplacedSellTxn();
    }
  }, [replacedTxId, isBuy]);

  return { setReplacedTxId, replacedTxInfo };
}
