import { FC, useCallback, useMemo, useState } from 'react';
import { ColumnsType } from 'rc-table/lib/interface';
import { useRouter } from 'next/router';
import { Routes } from '@/constants/routes';
import { useRefundPayment } from '@/api/queryHooks/useBuyCryptoController';
import { showNotification } from '@/utils/showNotification';
import { Button } from '../../buttons';
import styles from './TransactionRefund.module.css';
import { DataTableType, Table, TableCell, TableHeadCell } from '../../Table';

interface TransactionRefundProps {
  onRestart?: () => void;
  onModalClose: () => void;
  transactionData: {
    id: string;
    type: string;
    amount: string;
    crypto: string;
  };
}

export const TransactionRefund: FC<TransactionRefundProps> = ({ onRestart, onModalClose, transactionData }) => {
  const [step, setStep] = useState(1);
  const router = useRouter();

  const { mutate: refund } = useRefundPayment({
    onSuccess() {
      showNotification('Success! Refunds take 1-2 business days.', 'success');
      onModalClose();
    },
    onError() {
      showNotification('Something went wrong. Please contact support');
    },
  });

  const onRestartHandler = () => {
    if (onRestart) onRestart();
    onModalClose();
  };

  const onHomePageHandler = useCallback(() => {
    router.push(Routes.OVERVIEW);
  }, [router]);

  const onRefundRequest = async () => {
    await refund({ id: transactionData?.id });
  };

  const tableData = useMemo(() => {
    return [
      {
        key: 'refund',
        ...transactionData,
      },
    ];
  }, [transactionData]);

  const columns: ColumnsType<DataTableType> = [
    {
      title: <TableHeadCell sort={false}>Type</TableHeadCell>,
      align: 'left',
      dataIndex: 'type',
      render: (value: string) => <TableCell>{value}</TableCell>,
      key: 'type',
    },
    {
      title: <TableHeadCell sort={false}>$ Amount</TableHeadCell>,
      align: 'left',
      dataIndex: 'amount',
      render: (value: string) => <TableCell>{value}</TableCell>,
      key: 'amount',
    },
    {
      title: <TableHeadCell sort={false}>Crypto</TableHeadCell>,
      align: 'left',
      dataIndex: 'crypto',
      render: (value: string) => <TableCell>{value}</TableCell>,
      key: 'crypto',
    },
  ];

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className={styles.container}>
            <h3 className={styles.title}>Have you transfered the founds?</h3>

            <p className={styles.description}>
              The rate we locked from our exchange partner is expired. If you haven&apos;t transferred the funds yet,
              please RESTART a new transaction. If you already transferred the funds, click YES.
            </p>

            <div className={styles.buttonBox}>
              <Button className={styles.button} onPress={() => setStep(2)}>
                Yes
              </Button>
              <Button variants="secondary" className={styles.button} onPress={onRestartHandler}>
                Need more time, restart
              </Button>
            </div>
          </div>
        );

      case 2:
        return (
          <div className={styles.containerLarge}>
            <h3 className={styles.title}>Your payment is delayed</h3>

            <div className={styles.description}>
              Some banks don&apos;t release the funds instantly for first time recipients due to their internal security
              rules.
              <p>There might be up to 24hrs delay until we actually receive your transfer.</p>
            </div>

            <ul className={styles.list}>
              <li className={styles.listTitle}>What&apos;s next?</li>
              <li className={styles.listItem}>
                We&apos;ll receive the funds from your bank with a delay, we can offer you 2 options:
              </li>
              <li className={styles.listItem}>
                1. Buy your choice of digital currency at best possible market rate as soon as funds are cleared.{' '}
                <span className={styles.listItemBold}>This is the default option.</span>
              </li>
              <li className={styles.listItem}>
                2. Refund the transferred amount back to your bank account once it is cleared.{' '}
                <span className={styles.listItemBold}>Refunds take 1-2 business days.</span>
              </li>
            </ul>

            <div className={styles.tableBox}>
              <p className={styles.listTitle}>Transaction Summary:</p>
              <Table data={tableData} columns={columns} totalElements={1} />
            </div>

            <div className={styles.buttonBox}>
              <Button className={styles.button} onPress={onHomePageHandler}>
                Home Page
              </Button>
              <Button variants="secondary" className={styles.button} onPress={onRefundRequest}>
                Refund Request
              </Button>
            </div>
          </div>
        );

      default:
        return '';
    }
  };

  return <>{renderStep()}</>;
};
