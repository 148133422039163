import { Roles } from '@/constants/roles';
import { UserTypeBE } from '@/api';
import { PaymentType } from '@/api/common.types';
import { CryptoFrontendStatus } from '@/api/queryHooks/useTransactionsController';
import { PaymentStatusType } from '@/constants/paymentStatuses';

export function mapUserRoleToUserTypeBE(role?: Roles): UserTypeBE | null {
  switch (role) {
    case Roles.PERSONAL:
      return UserTypeBE.INDIVIDUAL;
    case Roles.BUSINESS:
      return UserTypeBE.BUSINESS;
    default:
      return null;
  }
}

export function mapUserTypeBEToUserRole(userTypeBe?: UserTypeBE): Roles | null {
  switch (userTypeBe) {
    case UserTypeBE.INDIVIDUAL:
      return Roles.PERSONAL;
    case UserTypeBE.BUSINESS:
      return Roles.BUSINESS;
    default:
      return null;
  }
}

export function mapTxTypeDtoToTxType(paymentType?: PaymentType) {
  switch (paymentType) {
    case PaymentType.NPP_BANK_TRANSFER:
      return 'Bank Transfer';
    case PaymentType.BPAY:
      return 'BPAY';
    case PaymentType.CARD_PAYMENT:
      return 'Credit Card';
    default:
      return paymentType;
  }
}

export function mapCryptoFrontendStatusToPaymentStatusType(frontendStatus?: CryptoFrontendStatus): PaymentStatusType {
  switch (frontendStatus) {
    case CryptoFrontendStatus.INITIATED: {
      return PaymentStatusType.Locked;
    }
    case CryptoFrontendStatus.PENDING_DEPOSIT: {
      return PaymentStatusType.Processing;
    }
    case CryptoFrontendStatus.DEPOSIT_RECEIVED: {
      return PaymentStatusType.Processing;
    }
    case CryptoFrontendStatus.EXPIRED: {
      return PaymentStatusType.Expired;
    }

    // TODO is is correct???
    case CryptoFrontendStatus.FAILED: {
      return PaymentStatusType.TxNotAllowed;
    }

    case CryptoFrontendStatus.TX_NOT_ALLOWED: {
      return PaymentStatusType.TxNotAllowed;
    }
    case CryptoFrontendStatus.PAYMENT_REFUNDED:
    case CryptoFrontendStatus.REPLACED:
    case CryptoFrontendStatus.PAID_OUT: {
      return PaymentStatusType.Completed;
    }

    default: {
      return PaymentStatusType.Empty;
    }
  }
}
