import Image from 'next/image';
import React from 'react';
import styles from './TraceBanner.module.css';

export const TraceBanner = () => {
  return (
    <div className={styles.banner}>
      <p className={styles.bannerText}>We offset our emissions with Trace. #ClimatePositiveOrganisation</p>
      <Image
        className={styles.bannerImage}
        src="/assets/TraceBannerLogo.svg"
        width={32}
        height={24}
        alt="Ledger"
        loading="eager"
      />
    </div>
  );
};
