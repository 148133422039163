import { PaymentStatusType } from '@/constants/paymentStatuses';
import { Button, Popup } from '@/components/common';
import { SUPPORT_EMAIL } from '@/constants/common';
import { useOverlayTriggerState } from 'react-stately';
import { useEffect, useState } from 'react';
import { OverlayProvider } from 'react-aria';
import { CryptoCurrency, PaymentType } from '@/api/common.types';
import { getLinkToTxInBlockchain } from '@/utils/functions';
import { mapTxTypeDtoToTxType } from '@/mappers';
import styles from './CheckoutStatusPopups.module.css';
import { TransactionRefund } from '../TransactionRefund';

interface CheckoutStatusProps {
  status: PaymentStatusType;
  onRetry?: () => void;
  replacedTxInfo?: null | { cryptoCurrency: CryptoCurrency; blockchainTxHash: string };
  transactionData?: {
    id: string;
    type: PaymentType | string;
    amount: string;
    crypto: string;
  };
  flowType?: 'buy' | 'sell';
}
export const CheckoutStatusPopups = ({
  status,
  onRetry,
  replacedTxInfo,
  transactionData,
  flowType,
}: CheckoutStatusProps) => {
  const popupState = useOverlayTriggerState({});
  const [isRefundOpen, setIsRefundOpen] = useState(false);

  function contactSupport() {
    window.location.assign(SUPPORT_EMAIL);
  }

  function viewTx() {
    window.location.assign(getLinkToTxInBlockchain(replacedTxInfo?.cryptoCurrency, replacedTxInfo?.blockchainTxHash));
  }

  function getContent() {
    switch (status) {
      case PaymentStatusType.Expired: {
        return {
          title: 'Transaction has expired',
          description:
            'Your transaction has expired as no payment was made within the allotted time. Please try again by initiating a new transaction.',
          buttonText: 'Retry Transaction',
          onClick: onRetry,
        };
      }
      case PaymentStatusType.TxNotAllowed: {
        return {
          title: 'Transaction failed',
          description:
            "We're sorry, your transaction was not allowed due to an issue. Please contact our support team for further assistance.",
          buttonText: 'Contact Support',
          onClick: contactSupport,
        };
      }
      case PaymentStatusType.PaymentRefunded: {
        return {
          title: 'Transaction was refunded',
          description:
            "We're sorry, your payment has been refunded due to compliance reasons. Please contact our support team for further assistance.",
          buttonText: 'Contact Support',
          onClick: contactSupport,
        };
      }
      case PaymentStatusType.Replaced: {
        return {
          title: 'Transaction was received',
          description:
            "Your payment was received past the allocated time period, but we've got you covered. We replaced your expired transaction with a new quote that is just as competitive. Please click the link below to view the new transaction details. Thank you for using RelayPay.",
          buttonText: 'View Transaction',
          onClick: viewTx,
        };
      }
      default: {
        return { title: '', description: '', buttonText: '' };
      }
    }
  }

  useEffect(() => {
    if (
      (status === PaymentStatusType.Expired && flowType === 'sell') ||
      status === PaymentStatusType.TxNotAllowed ||
      status === PaymentStatusType.PaymentRefunded ||
      status === PaymentStatusType.Replaced
    ) {
      popupState.open();
    } else {
      popupState.close();
    }

    if (status === PaymentStatusType.Expired && flowType === 'buy') {
      setIsRefundOpen(true);
      popupState.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);
  return (
    <OverlayProvider>
      <Popup isOpen={popupState.isOpen} onClose={popupState.close} isDismissable={false} hasCloseIcon>
        <div className={styles.container}>
          <h3 className={styles.title}>{getContent().title}</h3>
          <p className={styles.description}>{getContent().description}</p>
          <Button
            fluid
            isDisabled={
              status === PaymentStatusType.Replaced &&
              !replacedTxInfo?.blockchainTxHash &&
              !replacedTxInfo?.cryptoCurrency
            }
            onPress={() => getContent().onClick?.()}
            {...(status === PaymentStatusType.Replaced && {
              icon: 'externalLink',
              iconPosition: 'right',
            })}
          >
            {getContent().buttonText}
          </Button>
        </div>
      </Popup>

      <Popup isOpen={isRefundOpen} onClose={() => setIsRefundOpen(false)} isDismissable={false} hasCloseIcon>
        <TransactionRefund
          onRestart={onRetry}
          onModalClose={() => setIsRefundOpen(false)}
          transactionData={{
            id: String(transactionData?.id || ''),
            amount: String(transactionData?.amount || ''),
            crypto: String(transactionData?.crypto || ''),
            type: String(mapTxTypeDtoToTxType(transactionData?.type as PaymentType) || ''),
          }}
        />
      </Popup>
    </OverlayProvider>
  );
};
