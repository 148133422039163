import clsx from 'clsx';
import React, { ReactNode } from 'react';
import styles from './TableCell.module.css';

interface TableCellProps {
  className?: string;
  width?: string | number;
  isBold?: boolean;
  expandable?: boolean;
  withEllipsis?: boolean;
  children: ReactNode;
}

export const TableCell = ({ children, className, width, isBold, expandable, withEllipsis }: TableCellProps) => {
  return (
    <div
      title={typeof children === 'string' && withEllipsis ? children : undefined}
      style={{ minWidth: width }}
      className={clsx(styles.col, className, {
        [styles.bold]: isBold,
        [styles.expandable]: expandable,
        [styles.withEllipsis]: withEllipsis,
      })}
    >
      {children}
    </div>
  );
};
