import clsx from 'clsx';
import { find, findIndex, map } from 'lodash';
import { useCallback } from 'react';
import { useWindowSize } from 'usehooks-ts';
import { ItemTypeSelect, SelectRC } from '../form';
import { FiltersDataType, FiltersProps } from './types';

export const Filters = ({ data, className, onChangeData }: FiltersProps) => {
  const { width } = useWindowSize();
  const onChange = useCallback(
    (dataItem: FiltersDataType, option: ItemTypeSelect) => {
      const shallowData = [...data];
      const options = map(dataItem?.options, (subOption) => {
        if (option?.value === subOption?.value) {
          return { ...subOption, selected: true };
        }
        return { ...subOption, selected: false };
      });
      const dataIdx = findIndex(shallowData, { name: dataItem.name });

      if (dataIdx !== -1) {
        shallowData.splice(dataIdx, 1, {
          name: dataItem.name,
          key: dataItem.key,
          options,
        });
        onChangeData(shallowData);
      }
    },
    [data, onChangeData],
  );

  return (
    <div className={clsx(className)}>
      {map(data, (item) => {
        const selectedOption = find(item.options, { selected: true });

        return (
          <SelectRC
            variant="filter"
            value={selectedOption}
            onChange={(option) => onChange(item, option)}
            key={item.name}
            options={item.options}
            placeholder={item.name}
            filterSelect
            isBuy
            size={width > 1000 ? 'medium' : 'small'}
          />
        );
      })}
    </div>
  );
};
