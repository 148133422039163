import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import { axiosClient, url } from '@/api';
import {
  BuyCryptoTxnResponse,
  CryptoCurrency,
  CryptoNetwork,
  FiatCurrency,
  IList,
  MerchantPayment,
  PaymentType,
  SearchPaginationFilterParams,
} from '../common.types';
import { IUserReferralDetail } from '@/api/queryHooks/useReferralController';
import { IConvertFiatToCryptoParameters } from '@/api/queryHooks/useBuyCryptoController';

export enum CryptoPayoutStatus {
  PAID = 'PAID',
  UNPAID = 'UNPAID',
  MANUAL_PAYOUT = 'MANUAL_PAYOUT',
  INSUFFICIENT_BALANCE_TO_PAYOUT = 'INSUFFICIENT_BALANCE_TO_PAYOUT',
  VERIFY_PAYOUT = 'VERIFY_PAYOUT',
  RETRY_BANK_TRANSFER = 'RETRY_BANK_TRANSFER',
  FLASH_FX = 'FLASH_FX',
  MANUAL_FX_PAYOUT = 'MANUAL_FX_PAYOUT',
  FLASH_FX_WEEKEND = 'FLASH_FX_WEEKEND',
  FLASH_FX_PAID = 'FLASH_FX_PAID',
  GIFTA = 'GIFTA',
  MANUAL_GIFTA_PAYOUT = 'MANUAL_GIFTA_PAYOUT',
}

export enum SettlementStatus {
  PAID_OUT = 'PAID_OUT',
  PENDING = 'PENDING',
  WAITING_FOR_MANUAL_PAID_OUT = 'WAITING_FOR_MANUAL_PAID_OUT',
  NOT_PAID_OUT = 'NOT_PAID_OUT',
}

export enum CryptoPayIdType {
  ABN = 'ABN',
  EMAIL = 'EMAIL',
  PHONE_NUMBER = 'PHONE_NUMBER',
  ORGANISATION_ID = 'ORGANISATION_ID',
}

// BE STATUS => FE STATUS sent from BE
// INITIATED INITIATED

// PENDING_DEPOSIT PENDING_DEPOSIT

// INSUFFICIENT_DEPOSIT DEPOSIT_RECEIVED
// DEPOSIT_RECEIVED DEPOSIT_RECEIVED
// FIAT_TRANSFERRED_TO_EXCHANGE DEPOSIT_RECEIVED
// VERIFY_FIAT_TRANSFERRED_TO_EXCHANGE DEPOSIT_RECEIVED
// VERIFY_PAYOUT DEPOSIT_RECEIVED
// FIAT_TRANSFERRED_TO_CHILD_ACCOUNT DEPOSIT_RECEIVED
// TENTATIVE_AT_BLOCKCHAIN DEPOSIT_RECEIVED
// CONFIRMED_AT_BLOCKCHAIN DEPOSIT_RECEIVED
// CONFIRMED_AT_EXCHANGE DEPOSIT_RECEIVED
// EXECUTED_AT_EXCHANGE DEPOSIT_RECEIVED
// LIQUIDATED_AT_EXCHANGE DEPOSIT_RECEIVED
// FINALIZED_AT_EXCHANGE DEPOSIT_RECEIVED
// FLASH_FX DEPOSIT_RECEIVED
// FLASH_FX_PAID DEPOSIT_RECEIVED
// MANUAL_INTERVENTION_REQUIRED DEPOSIT_RECEIVED
// MANUAL_PAYOUT DEPOSIT_RECEIVED
// MANUAL_FX_PAYOUT DEPOSIT_RECEIVED
// KYT_IS_DOWN DEPOSIT_RECEIVED
// MANUAL_REVERT_REQUIRED DEPOSIT_RECEIVED
// GIFT_CARD_PROCESSING DEPOSIT_RECEIVED

// CANCELLED CANCELLED

// EXPIRED EXPIRED

// PAID PAID_OUT

// TX_NOT_ALLOWED TX_NOT_ALLOWED

// NAME_MISMATCH NAME_MISMATCH

// FAILED FAILED

// REPLACED REPLACED

// LATE_PAYMENT_REFUNDED PAYMENT_REFUNDED
// PAYMENT_REFUNDED PAYMENT_REFUNDED

export enum CryptoFrontendStatus {
  INITIATED = 'INITIATED',
  PENDING_DEPOSIT = 'PENDING_DEPOSIT',
  DEPOSIT_RECEIVED = 'DEPOSIT_RECEIVED',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
  PAID_OUT = 'PAID_OUT',
  TX_NOT_ALLOWED = 'TX_NOT_ALLOWED',
  NAME_MISMATCH = 'NAME_MISMATCH',
  FAILED = 'FAILED',
  REPLACED = 'REPLACED',
  PAYMENT_REFUNDED = 'PAYMENT_REFUNDED',
}

export interface SellCryptoTxsResponse {
  description?: string;
  firstName?: string;
  mobile?: string;
  surname?: string;
  email?: string;
  cryptoCurrency?: CryptoCurrency;
  blockchainTxHash?: string;
  cryptoNetwork?: CryptoNetwork;
  customerWalletAddress?: string;
  created?: string;
  cryptoAmount?: number;
  systemDepositAddress?: string;
  updated?: string;
  relayPayUserId?: string;
  aba?: string;
  bic?: string;
  iban?: string;
  accountNo?: string;
  payoutStatus?: CryptoPayoutStatus;
  clabe?: string;
  cnaps?: string;
  ifsc?: string;
  sortCode?: string;
  payoutType?: PaymentType;
  billerCode?: string;
  billerReference?: string;
  fiatAmount?: number;
  payId?: string;
  bsb?: string;
  bankAccountNr?: string;
  accountName?: string;
  fiatCurrency?: FiatCurrency;
  payoutCountryCode?: string;
  payIdType?: CryptoPayIdType;
  frontendStatus?: CryptoFrontendStatus;
  id?: string;
  blockChainExplorer?: string | null;
}

export function useBankTxs<TSelectData = IList<SellCryptoTxsResponse>, TError = AxiosError>(
  options: [
    SearchPaginationFilterParams,
    Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
  ],
): UseQueryResult<TSelectData, TError> {
  const queryKeys = options[0];
  return useQuery<void, TError, TSelectData>(
    ['BankTxs', ...Object.values(queryKeys)],
    () =>
      axiosClient.get(
        url.transactionsController.getBankTxs({
          page: queryKeys?.page ?? 0,
          size: queryKeys?.size ?? 8,
          sortField: queryKeys?.sortField ?? 'created',
          isDesc: queryKeys?.isDesc ?? true,
        }),
      ),
    options[1],
  );
}

export function useBankTxsCsv<TSelectData = string, TError = AxiosError>(
  options?: Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
): UseQueryResult<TSelectData, TError> {
  return useQuery<void, TError, TSelectData>(
    ['BankTxsCsv'],
    () => axiosClient.get(url.transactionsController.getBankTxsCsv),
    options,
  );
}

export function useBillTxs<TSelectData = IList<SellCryptoTxsResponse>, TError = AxiosError>(
  options: [
    SearchPaginationFilterParams,
    Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
  ],
): UseQueryResult<TSelectData, TError> {
  const queryKeys = options[0];
  return useQuery<void, TError, TSelectData>(
    ['BillTxs', ...Object.values(queryKeys)],
    () =>
      axiosClient.get(
        url.transactionsController.getBillTxs({
          page: queryKeys?.page ?? 0,
          size: queryKeys?.size ?? 8,
          sortField: queryKeys?.sortField ?? 'created',
          isDesc: queryKeys?.isDesc ?? true,
        }),
      ),
    options[1],
  );
}

export function useBillTxsCsv<TSelectData = string, TError = AxiosError>(
  options?: Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
): UseQueryResult<TSelectData, TError> {
  return useQuery<void, TError, TSelectData>(
    ['BillTxsCsv'],
    () => axiosClient.get(url.transactionsController.getBillTxsCsv),
    options,
  );
}

export function useGetBuyCryptoTxs<TSelectData = IList<BuyCryptoTxnResponse>, TError = AxiosError>(
  options: [
    SearchPaginationFilterParams,
    Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
  ],
): UseQueryResult<TSelectData, TError> {
  const queryKeys = options[0];

  return useQuery<void, TError, TSelectData>(
    ['BuyCryptoTxs', ...Object.values(queryKeys)],
    () =>
      axiosClient.get(
        url.transactionsController.getBuyCryptoTxs({
          page: queryKeys?.page ?? 0,
          size: queryKeys?.size ?? 8,
          sortField: queryKeys?.sortField ?? 'created',
          isDesc: queryKeys?.isDesc ?? true,
        }),
      ),
    options[1],
  );
}

export function useBuyCryptoTxsCsv<TSelectData = string, TError = AxiosError>(
  options?: Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
): UseQueryResult<TSelectData, TError> {
  return useQuery<void, TError, TSelectData>(
    ['BuyCryptoTxsCsv'],
    () => axiosClient.get(url.transactionsController.getBuyCryptoTxsCsv),
    options,
  );
}

export function useMerchantPayments<TSelectData = IList<MerchantPayment>, TError = AxiosError>(
  options: [
    SearchPaginationFilterParams,
    Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
  ],
): UseQueryResult<TSelectData, TError> {
  const queryKeys = options[0];

  return useQuery<void, TError, TSelectData>(
    ['MerchantPayments', ...Object.values(queryKeys)],
    () =>
      axiosClient.get(
        url.transactionsController.getMerchantPayments({
          page: queryKeys?.page ?? 0,
          size: queryKeys?.size ?? 8,
          sortField: queryKeys?.sortField ?? 'created',
          isDesc: queryKeys?.isDesc ?? true,
        }),
      ),
    options[1],
  );
}

export function useMerchantPaymentsCsv<TSelectData = string, TError = AxiosError>(
  options?: Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
): UseQueryResult<TSelectData, TError> {
  return useQuery<void, TError, TSelectData>(
    ['MerchantPaymentsCsv'],
    () => axiosClient.get(url.transactionsController.getMerchantPaymentsCsv),
    options,
  );
}
