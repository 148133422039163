import { CoinNetworksResponse, useGetCoinNetworks } from '@/api';
import { CryptoCurrency } from '@/api/common.types';
import { ChainsDataStore, useChainsData } from '@/store/chainsData';
import { walletConnectSupportList } from '@/utils/constants';
import { useCallback, useMemo } from 'react';
import { shallow } from 'zustand/shallow';

function convertToHex(chainId: String | null | undefined) {
  if (typeof chainId === 'string') {
    const splitChainId = chainId.split(':');
    if (splitChainId[0].includes('eip155')) {
      const hexChainId = Number(splitChainId?.[1]).toString(16);

      return `0x${hexChainId}`;
    }
  }
  return chainId;
}

interface IConvertedFiatToCryptoData {
  cryptoCurrency: CryptoCurrency;
  onSuccess?: (data: CoinNetworksResponse[]) => void;
  enabled?: boolean;
}

const formatChainsFromResponse = (data: CoinNetworksResponse[]) => {
  // Filter out chains that are not supported by wallet connect and are not eip155 (ethereum chains)
  return data
    .filter(
      (item) =>
        !!item?.chainId &&
        walletConnectSupportList.includes(item?.cryptoNetwork) &&
        item?.chainId?.startsWith('eip155'),
    )
    .map((item) => ({
      id: convertToHex(item?.chainId),
      token: item?.cryptoNetwork,
      label: item?.description,
      rpcUrl: item?.rpcUrl,
    })) as ChainsDataStore['chainsData'];
};

export const useGetCryptoNetworkData = ({ cryptoCurrency, onSuccess, enabled }: IConvertedFiatToCryptoData) => {
  const { data, isLoading, isFetching, error, refetch } = useGetCoinNetworks([
    { cryptoCurrency },
    {
      onSuccess: (data) => {
        setChainsData({ chainsData: formatChainsFromResponse(data) });
        if (onSuccess) {
          onSuccess(data);
        }
      },
      enabled,
    },
  ]);

  const [, setChainsData] = useChainsData((state) => [state.chainsData, state.setData], shallow);

  const filterNetworkData = useCallback(
    (networkData: CryptoCurrency) => {
      switch (networkData) {
        case 'ETH':
          return data?.sort((a, b) => (a.cryptoNetwork === 'ETH' ? -1 : 1));
        case 'BTC':
          return data?.sort((a, b) => (a.cryptoNetwork === 'BTC' ? -1 : 1));
        case 'USDT':
          return data?.sort((a, b) => (a.cryptoNetwork === 'ETH' ? -1 : 1));
        case 'USDC':
          return data?.sort((a, b) => (a.cryptoNetwork === 'ETH' ? -1 : 1));
        case 'DAI':
          return data?.sort((a, b) => (a.cryptoNetwork === 'ETH' ? -1 : 1));
        case 'SOL':
          return data?.sort((a, b) => (a.cryptoNetwork === 'SOL' ? -1 : 1));
        case 'BNB':
          return data?.sort((a, b) => (a.cryptoNetwork === 'BSC' ? -1 : 1));
        case 'MATIC':
          return data?.sort((a, b) => (a.cryptoNetwork === 'MATIC' ? -1 : 1));
        case 'UNISWAP':
          return data?.sort((a, b) => (a.cryptoNetwork === 'ETH' ? -1 : 1));

        default:
          return data;
      }
    },
    [data],
  );

  const filteredData = useMemo(() => {
    return filterNetworkData(cryptoCurrency);
  }, [cryptoCurrency, filterNetworkData]);

  return {
    data: filteredData ?? [],
    isLoading,
    isFetching,
    error,
    refetch,
  };
};
