import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { Button } from '../../buttons';
import { Filters, FiltersDataType, useFilters } from '../../Filters';
import { Input } from '../../form';
import styles from './TableControls.module.css';

interface TableControlsProps {
  onSearch?: Dispatch<SetStateAction<string>>;
  filterable?: boolean;
  searchable?: boolean;
  filters?: FiltersDataType[];
  getFilteredOptions: (filteredOptions: {}) => void;
}

export const TableControls = ({
  onSearch,
  filterable,
  searchable,
  filters,
  getFilteredOptions,
}: TableControlsProps) => {
  const { isDesktop } = useMediaQuery();
  const [filter, setFilter] = useState(false);
  const { filtersData, groupedSelectedFiltersByKey, setFiltersData } = useFilters(filters || []);

  useEffect(() => {
    getFilteredOptions(groupedSelectedFiltersByKey);
  }, [groupedSelectedFiltersByKey, getFilteredOptions]);

  return (
    <div className={styles.head}>
      <div className={styles.headInner}>
        {filterable && (
          <Button
            variants="subtle"
            size={isDesktop ? 'mediumSubtle' : 'smallSubtle'}
            icon="addFilter"
            onPress={() => setFilter(!filter)}
            className={styles.button}
          >
            {!filter ? 'Add Filter' : 'Clear Filters'}
          </Button>
        )}
        {searchable && (
          <Input
            iconLeft="search"
            size={isDesktop ? 'large' : 'medium'}
            iconLeftClassName={styles.searchIcon}
            placeholder={isDesktop ? 'Search for something' : 'Search'}
            className={styles.input}
            onChange={onSearch}
          />
        )}
      </div>
      {filter && (
        <Filters
          className={styles.filterList}
          data={filtersData}
          onChangeData={(newData) => {
            setFiltersData(newData);
          }}
        />
      )}
    </div>
  );
};
