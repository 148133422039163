import {
  UseMutationOptions,
  UseMutationResult,
  useMutation,
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from 'react-query';
import { axiosClient, url } from '@/api';
import { AxiosError } from 'axios';
import { CryptoCurrency, ErrorObject, FiatCurrency, PaymentType, SellCryptoTxResponse } from '../common.types';
import { IPricesItem } from './useBuyCryptoController';

export interface CreateGiftCardTxs {
  fiatCurrency: FiatCurrency;
  cryptoCurrency: CryptoCurrency;
  fiatAmount: number;
  giftCardStoreId?: number;
  sourceAddress?: string;
  cryptoNetwork: CryptoCurrency;
}

export interface CreateSellTxs {
  payoutType: PaymentType;
  fiatCurrency: FiatCurrency;
  cryptoCurrency: CryptoCurrency;
  fiatAmount: number;

  sourceAddress?: string;
  cryptoNetwork?: string;
  billerReference?: string;
  billerCode?: string;
  bsb?: string;
  bankAccountNr?: string;
  bic?: string;
  aba?: string;
  accountNo?: string;
  iban?: string;
  accountName?: string;
  description?: string;
  cnaps?: string;
  ifsc?: string;
  sortCode?: string;
  payoutCountryCode?: string;
  paymentDestination?: string;
}

export interface UpdateSellTxs {
  transactionId: string;
  sourceAddress: string;
  blockchainHash: string;
}

export function useCreateGiftCardTxs<TContext>(
  options?: Omit<
    UseMutationOptions<SellCryptoTxResponse, AxiosError<ErrorObject>, CreateGiftCardTxs, TContext>,
    'mutationKey' | 'mutationFn'
  >,
): UseMutationResult<SellCryptoTxResponse, AxiosError<ErrorObject>, CreateGiftCardTxs, TContext> {
  const key = ['CreateGiftCardTxs'];

  return useMutation((body: CreateGiftCardTxs) => axiosClient.post(url.sellCryptoController.createGiftCardTxn, body), {
    ...options,
    mutationKey: key,
  });
}

export function useValidateBpay<TSelectData = { billerName: string }, TError = AxiosError>(
  options?: [{ bpay: string }, Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined],
): UseQueryResult<TSelectData, TError> {
  return useQuery<void, TError, TSelectData>(
    ['ValidateBpay'],
    () => axiosClient.get(url.sellCryptoController.validateBpay(options?.[0].bpay)),
    options?.[1],
  );
}

export function useValidateBsb<TSelectData = { bankCode: string }, TError = AxiosError>(
  options?: [{ bsb: string }, Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined],
): UseQueryResult<TSelectData, TError> {
  return useQuery<void, TError, TSelectData>(
    ['ValidateBsb'],
    () => axiosClient.get(url.sellCryptoController.validateBsb(options?.[0].bsb)),
    options?.[1],
  );
}

export function useCreateSellTxs<TContext>(
  options?: Omit<
    UseMutationOptions<SellCryptoTxResponse, AxiosError<ErrorObject>, CreateSellTxs, TContext>,
    'mutationKey' | 'mutationFn'
  >,
): UseMutationResult<SellCryptoTxResponse, AxiosError<ErrorObject>, CreateSellTxs, TContext> {
  const key = ['CreateSellTxs'];

  return useMutation((body: CreateSellTxs) => axiosClient.post(url.sellCryptoController.createTxn, body), {
    ...options,
    mutationKey: key,
  });
}

export function useUpdateSellTxs<TContext>(
  options?: Omit<
    UseMutationOptions<SellCryptoTxResponse, AxiosError<ErrorObject>, UpdateSellTxs, TContext>,
    'mutationKey' | 'mutationFn'
  >,
): UseMutationResult<SellCryptoTxResponse, AxiosError<ErrorObject>, UpdateSellTxs, TContext> {
  const key = ['UpdateSellTxs'];

  return useMutation((body: UpdateSellTxs) => axiosClient.patch(url.sellCryptoController.createTxn, body), {
    ...options,
    mutationKey: key,
  });
}

export function useGetSellTxn<TSelectData = SellCryptoTxResponse, TError = AxiosError>(
  options?: [{ id?: string }, Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined],
): UseQueryResult<TSelectData, TError> {
  return useQuery<void, TError, TSelectData>(
    ['GetSellTxn', options?.[0]?.id],
    () => axiosClient.get(url.sellCryptoController.singleTxn(options?.[0]?.id)),
    options?.[1],
  );
}

export function useCancelSellTxn<TContext>(
  options?: Omit<UseMutationOptions<void, AxiosError<ErrorObject>, string, TContext>, 'mutationKey' | 'mutationFn'>,
): UseMutationResult<void, AxiosError<ErrorObject>, string, TContext> {
  const key = ['CancelSellTxn'];

  return useMutation((id?: string) => axiosClient.delete(url.sellCryptoController.singleTxn(id)), {
    ...options,
    mutationKey: key,
  });
}

export function useGetSellPrices<TSelectData = IPricesItem, TError = AxiosError>(
  options?: [
    { crypto?: string; fiat?: string; amount?: string | number; payoutmethod?: PaymentType; network?: string },
    Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
  ],
): UseQueryResult<TSelectData, TError> {
  return useQuery<void, TError, TSelectData>(
    ['GetSellPrices'],
    () =>
      axiosClient.get(
        url.sellCryptoController.getPrices(
          options?.[0]?.fiat,
          options?.[0]?.crypto,
          options?.[0]?.network,
          options?.[0]?.payoutmethod,
          options?.[0]?.amount,
        ),
      ),
    options?.[1],
  );
}
