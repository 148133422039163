import React, { useEffect, useState } from 'react';
import RCTable from 'rc-table';
import { ColumnsType, ExpandedRowRender } from 'rc-table/lib/interface';
import clsx from 'clsx';
import { useDebounce, useWindowSize } from 'usehooks-ts';
import { PaginationComponent } from '../Pagination';
import styles from './Table.module.css';
import { Icon } from '../Icon';
import { TableControls } from './TableControls';
import { TableEmptyState } from './TableEmptyState';
import { FiltersDataType } from '../Filters';

export interface DataTableType {
  key: number | string;
  [key: string]: number | string | boolean | { [key: string]: number | string | undefined } | undefined;
  expandedData?: {
    [key: string]: number | string | undefined;
  };
}
interface TableProps {
  data: DataTableType[];
  columns: ColumnsType<DataTableType>;
  searchable?: boolean;
  expandable?: boolean;
  expandedRowRender?: ExpandedRowRender<DataTableType>;
  filterable?: boolean;
  totalRewards?: number;
  hasTotalRewardInfo?: boolean;
  typeOfData?: string;
  filters?: FiltersDataType[];
  onSearch?: (query: string) => void;
  onPaginate?: (page: number) => void;
  totalElements?: number;
  pageSize?: number;
}

export const Table = ({
  data,
  columns,
  expandable,
  filterable,
  searchable,
  totalRewards,
  typeOfData,
  filters,
  hasTotalRewardInfo = false,
  expandedRowRender,
  onSearch,
  onPaginate,
  totalElements,
  pageSize,
}: TableProps) => {
  const { width } = useWindowSize();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchField, setSearchField] = useState('');
  const debouncedSearch = useDebounce(searchField);

  // TODO: filters initial state
  const [filteredOptions, setFilteredOptions] = useState({});

  // TODO: filter types
  const getFilteredOptions = (value: {}) => {
    setFilteredOptions(value);
  };

  // // TODO: filters
  // const filteredData = useMemo(() => {
  //   if (filteredOptions) {
  //     return data;
  //   }
  //
  //   return data;
  // }, [data, filteredOptions]);

  // const searchedData = useMemo(() => {
  //   if (searchable) {
  //     if (searchField.trim()) {
  //       return filter(filteredData, (item) => {
  //         return some(values(item), (value) =>
  //           includes(String(value).toLocaleLowerCase(), searchField.toLocaleLowerCase()),
  //         );
  //       });
  //     }
  //   }
  //
  //   return filteredData;
  // }, [searchField, filteredData, searchable]);
  //
  // const paginatedData = useMemo(() => {
  //   if (paginatable) {
  //     const to = COUNT_PER_PAGE * currentPage;
  //     const from = to - COUNT_PER_PAGE;
  //
  //     return searchedData.slice(from, to);
  //   }
  //   return data;
  // }, [currentPage, data, paginatable, searchedData]);

  useEffect(() => {
    onSearch?.(debouncedSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);
  useEffect(() => {
    onPaginate?.(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <>
      <div className={clsx(styles.table, { [styles.expandableTable]: expandable })}>
        <div
          className={clsx(styles.tableWrapper, {
            [styles.profilePageTableWrapper]: totalRewards,
            [styles.apiTokenPageTableWrapper]: !hasTotalRewardInfo,
          })}
        >
          {(filterable || searchable) && (
            <TableControls
              onSearch={setSearchField}
              searchable={searchable}
              filterable={filterable}
              filters={filters}
              getFilteredOptions={getFilteredOptions}
            />
          )}
          <RCTable
            columns={columns}
            scroll={{ x: '100%' }}
            style={{ width: '100%' }}
            data={data}
            emptyText={<TableEmptyState />}
            expandable={
              expandable
                ? {
                    expandRowByClick: true,
                    expandedRowClassName: () => styles.expandedAlready,
                    expandedRowRender,
                    expandIcon: ({ expanded }) => {
                      return (
                        <Icon
                          name={expanded ? 'chevronUp' : 'chevronDown'}
                          className={clsx(styles.chevronIcon, {
                            [styles.collapsedIcon]: expanded,
                          })}
                          size={18}
                        />
                      );
                    },
                  }
                : undefined
            }
          />
          {width < 1000 && (
            <>
              <div className={styles.tableBlur} />
              <div className={clsx(styles.tableBlur, styles.tableBlurLeft)} />
            </>
          )}
          {hasTotalRewardInfo && (
            <div className={styles.total}>
              <div className={styles.totalInner}>
                <span>Total Rewards: ${totalRewards} AUD</span>
              </div>
            </div>
          )}
        </div>
        {!!pageSize && !!totalElements && totalElements > pageSize && (
          <PaginationComponent
            totalElements={totalElements}
            pageSize={pageSize}
            currentPage={currentPage}
            updatePage={setCurrentPage}
            typeOfData={typeOfData}
          />
        )}
      </div>
    </>
  );
};
