import { Button, ButtonProps, Popup } from '@/components/common';
import { useOverlayTriggerState } from 'react-stately';
import { PressEvent } from '@react-types/shared';
import { OverlayProvider } from 'react-aria';
import { ConfirmAction } from '@/components/common/popups/ConfirmAction';

interface ConfirmationPopupWithTriggerProps {
  triggerButtonProps: ButtonProps;
  onSubmit: () => void;
  onReturn?: () => void;
  isLoading?: boolean;
}

export const ConfirmationPopupWithTrigger = ({
  triggerButtonProps,
  onSubmit,
  onReturn,
  isLoading,
}: ConfirmationPopupWithTriggerProps) => {
  const confirmPopupState = useOverlayTriggerState({});

  function open(e: PressEvent) {
    confirmPopupState.open();
    triggerButtonProps.onPress?.(e);
  }

  function onReturnLocal() {
    onReturn?.();
    confirmPopupState.close();
  }

  return (
    <OverlayProvider>
      <Button {...triggerButtonProps} onPress={open}>
        {triggerButtonProps.children}
      </Button>
      <Popup isOpen={confirmPopupState.isOpen} onClose={confirmPopupState.close} hasCloseIcon>
        <ConfirmAction onSubmit={onSubmit} onReturn={onReturnLocal} isLoading={isLoading} />
      </Popup>
    </OverlayProvider>
  );
};
