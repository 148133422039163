import { Button } from '@/components/common';
import styles from './ConfirmAction.module.css';

interface ConfirmActionProps {
  onSubmit: () => void;
  onReturn: () => void;
  title?: string;
  description?: string;
  isLoading?: boolean;
}
export const ConfirmAction = ({
  onSubmit,
  onReturn,
  title = 'Are you sure?',
  description,
  isLoading,
}: ConfirmActionProps) => {
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.description}>{description}</p>
      <div className={styles.actions}>
        <Button variants="subtle" onPress={() => onReturn()} isDisabled={isLoading}>
          Return
        </Button>
        <Button onPress={() => onSubmit()} loading={isLoading} isDisabled={isLoading}>
          Confirm
        </Button>
      </div>
    </div>
  );
};
