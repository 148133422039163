import { create } from 'zustand';
import { Roles } from '@/constants/roles';
import { persist, subscribeWithSelector } from 'zustand/middleware';

interface ChainsDataStoreActionsInterface {
  reset: () => void;
  setData: (data: Partial<ChainsDataStore>) => void;
}

export interface ChainsDataStore {
  chainsData: {
    id: string;
    token: string;
    label: string;
    rpcUrl: string;
  }[];
}

const initialState: ChainsDataStore = {
  chainsData: [],
};

export const useChainsData = create(
  subscribeWithSelector(
    persist<ChainsDataStore & ChainsDataStoreActionsInterface>(
      (set) => ({
        ...initialState,
        setData(state: Partial<ChainsDataStore>) {
          set(() => ({ ...state }));
        },
        reset() {
          set(() => ({ ...initialState }));
        },
      }),
      {
        name: 'chainsData',
      },
    ),
  ),
);

useChainsData.getState().reset();
