import {
  useQuery,
  UseQueryResult,
  UseQueryOptions,
  UseMutationOptions,
  UseMutationResult,
  useMutation,
} from 'react-query';
import { axiosClient, url } from '@/api';
import { AxiosError } from 'axios';
import { BuyCryptoTxnResponse, CryptoCurrency, ErrorObject, FiatCurrency, PaymentType, Purpose } from '../common.types';

export interface IConvertFiatToCryptoParameters {
  purpose: Purpose;
  fiat?: string;
  crypto: string;
  amount: string;
  isPrivateCall?: boolean;
  group?: 'BUY' | 'TRANSFER' | 'BPAY' | 'MERCHANT' | 'GIFT_CARD';
  network?: CryptoCurrency;
}

export interface IPricesItem {
  base: string;
  baseAmount: string;
  sendingNetworkFee: string;
}

export interface IAmountRange {
  cryptoMin: number;
  maximum: number;
  minimum: number;
}

export interface IConvertedFiatToCryptoData {
  base: string;
  baseAmount: number;
  sendingNetworkFee: number;
}

export interface IVerifyWalletData {
  crypto: CryptoCurrency;
  address: string;
}

export interface GetBestPricesPayload {
  paymentType: PaymentType;
  cryptoCurrency: CryptoCurrency;
  fiatCurrency: FiatCurrency;
  fiatAmount: number;
  transferAddress: string;
  txSource: 'RELAYPAY';
  verificationId?: string;
  cryptoNetwork: CryptoCurrency;
  transferMemo?: string;
}
export interface RefundPaymentPayload {
  id: string;
}

export interface getWalletRespose {
  cryptoCurrency: string;
  cryptoNetwork: string;
  addressRegex: string;
}

export function useConvertFiatToCrypto<TSelectData = IConvertedFiatToCryptoData, TError = AxiosError<ErrorObject>>(
  options: [
    IConvertFiatToCryptoParameters,
    Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
  ],
): UseQueryResult<TSelectData, TError> {
  return useQuery<void, TError, TSelectData>(
    ['ConvertFiatToCrypto'],
    () => {
      return !!options[0].isPrivateCall
        ? axiosClient.get(
            url.commonCryptoController.convertPrivateFiatToCrypto(
              options[0].crypto ?? '',
              options[0].amount,
              options[0].purpose,
              options[0]?.fiat,
              options[0]?.group,
              options[0]?.network,
            ),
          )
        : axiosClient.get(
            url.commonCryptoController.convertFiatToCrypto(
              options[0].crypto ?? '',
              options[0].amount,
              options[0].purpose,
              options[0]?.fiat,
              options[0]?.group,
              options[0]?.network,
            ),
          );
    },
    options?.[1],
  );
}

export function useVerifyWallet<TSelectData = boolean, TError = AxiosError>(
  options: [IVerifyWalletData, Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined],
): UseQueryResult<TSelectData, TError> {
  return useQuery<void, TError, TSelectData>(
    ['VerifyWallet'],
    () => axiosClient.get(url.commonCryptoController.verifyWallet(options[0].crypto, options[0].address)),
    options?.[1],
  );
}

export function useGetAmountRange<TSelectData = IAmountRange, TError = AxiosError<ErrorObject>>(
  options: [
    { fiat?: string; crypto: string; paymentType?: PaymentType; isPrivateCall?: boolean },
    Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
  ],
): UseQueryResult<TSelectData, TError> {
  return useQuery<void, TError, TSelectData>(
    ['GetAmountRange'],
    () => {
      return !!options[0].isPrivateCall
        ? axiosClient.get(
            url.buyCryptoController.getPrivateAmountsRange(
              options[0]?.fiat,
              options[0].crypto ?? '',
              options[0].paymentType,
            ),
          )
        : axiosClient.get(
            url.buyCryptoController.getAmountsRange(options[0]?.fiat, options[0].crypto ?? '', options[0].paymentType),
          );
    },
    options?.[1],
  );
}

export function useCreateBuyTxn<TContext>(
  options?: Omit<
    UseMutationOptions<BuyCryptoTxnResponse, AxiosError<ErrorObject>, GetBestPricesPayload, TContext>,
    'mutationKey' | 'mutationFn'
  >,
): UseMutationResult<BuyCryptoTxnResponse, AxiosError<ErrorObject>, GetBestPricesPayload, TContext> {
  const key = ['GetBestPrices'];

  return useMutation((body: GetBestPricesPayload) => axiosClient.post(url.buyCryptoController.createTxn, body), {
    ...options,
    mutationKey: key,
  });
}

export function useGetBuyTxn<TSelectData = BuyCryptoTxnResponse, TError = AxiosError>(
  options?: [{ id?: string }, Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined],
): UseQueryResult<TSelectData, TError> {
  return useQuery<void, TError, TSelectData>(
    ['useGetTransaction', options?.[0]?.id],
    () => axiosClient.get(url.buyCryptoController.singleTxn(options?.[0]?.id)),
    options?.[1],
  );
}

export function useCancelBuyTxn<TContext>(
  options?: Omit<UseMutationOptions<void, AxiosError<ErrorObject>, string, TContext>, 'mutationKey' | 'mutationFn'>,
): UseMutationResult<void, AxiosError<ErrorObject>, string, TContext> {
  const key = ['CancelBuyTxn'];

  return useMutation((id: string) => axiosClient.delete(url.buyCryptoController.singleTxn(id)), {
    ...options,
    mutationKey: key,
  });
}

export function useRefundPayment<TContext>(
  options?: Omit<
    UseMutationOptions<void, AxiosError<ErrorObject>, RefundPaymentPayload, TContext>,
    'mutationKey' | 'mutationFn'
  >,
): UseMutationResult<void, AxiosError<ErrorObject>, RefundPaymentPayload, TContext> {
  const key = ['CancelBuyTxn'];

  return useMutation(
    async (body: RefundPaymentPayload) => await axiosClient.patch(url.buyCryptoController.refundPayment(body.id)),
    { ...options, mutationKey: key },
  );
}

// export function useGetWalletRegex<TSelectData = getWalletRespose, TError = AxiosError<ErrorObject>>(
//   options: [
//     { cryptoCurrency?: string; cryptoNetwork?: string },
//     Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
//   ],
// ): UseQueryResult<TSelectData, TError> {
//   return useQuery<void, TError, TSelectData>(
//     ['GetWalletRegex'],
//     () =>
//       axiosClient.get(url.buyCryptoController.getWalletRegex(options[0]?.cryptoCurrency, options[0]?.cryptoNetwork)),
//     options?.[1],
//   );
// }

export function useGetBuyPrices<TSelectData = IPricesItem, TError = AxiosError>(
  options?: [
    { crypto?: string; fiat?: string; amount?: string | number; payoutmethod?: PaymentType; network?: CryptoCurrency },
    Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
  ],
): UseQueryResult<TSelectData, TError> {
  return useQuery<void, TError, TSelectData>(
    ['GetBuyPrices'],
    () =>
      axiosClient.get(
        url.buyCryptoController.getPrices(
          options?.[0]?.fiat,
          options?.[0]?.crypto,
          options?.[0]?.payoutmethod,
          options?.[0]?.amount,
          options?.[0]?.network,
        ),
      ),
    options?.[1],
  );
}
