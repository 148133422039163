import { init, Web3OnboardProvider } from '@web3-onboard/react';
import React, { Children, FC, ReactNode, useEffect, useState } from 'react';
import injectedModule from '@web3-onboard/injected-wallets';
import ledgerModule from '@web3-onboard/ledger';
import trezorModule from '@web3-onboard/trezor';
import trustModule from '@web3-onboard/trust';
import coinbaseModule from '@web3-onboard/coinbase';
import walletConnectModule from '@web3-onboard/walletconnect';
import { useChainsData } from '@/store/chainsData';
import { shallow } from 'zustand/shallow';

const CONNECT_WALLET_PROJ_ID = '2fd4f71298dbe5985a7cec40b222df58';
const INFURA_KEY = 'e98719c098c84021b4ce253570818de6';

const wcInitOptions = {
  projectId: CONNECT_WALLET_PROJ_ID,
  qrModalOptions: {
    themeVariables: {
      '--wcm-z-index': '1000',
    },
  },
};
const injected = injectedModule();
const coinbase = coinbaseModule();
const trust = trustModule();
const ledger = ledgerModule({
  walletConnectVersion: 2,
  projectId: CONNECT_WALLET_PROJ_ID,
});
const walletConnect = walletConnectModule(wcInitOptions);

const trezorOptions = {
  appUrl: 'https://relaypay.io', // the url of your app (required for manifest)
  email: 'info@relaypay.io', // your contact email, (required for manifest)
};

const trezor = trezorModule(trezorOptions);

const wallets = [injected, trust, coinbase, ledger, trezor, walletConnect];

const defaultChains = [
  {
    id: '0x1',
    token: 'ETH',
    label: 'Ethereum Mainnet',
    rpcUrl: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
  },
];

const initWalletConnect = (chains: any) => {
  try {
    // Try to initialize with chains data with chains data gotten from the server
    return init({
      wallets,
      chains,
      connect: {
        showSidebar: false,
      },
      appMetadata: {
        name: 'RelayPay',
        description: 'RelayPay is a payment gateway for crypto payments.',
        icon: '/favicon/favicon-32x32.png',
      },
      accountCenter: {
        desktop: {
          position: 'bottomRight',
          enabled: true,
          minimal: false,
        },
        mobile: {
          position: 'bottomRight',
          enabled: true,
          minimal: false,
        },
      },
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    // If there is an error, initialize with default chains
    return init({
      wallets,
      chains: defaultChains,
      connect: {
        showSidebar: false,
      },
      appMetadata: {
        name: 'RelayPay',
        description: 'RelayPay is a payment gateway for crypto payments.',
        icon: '/favicon/favicon-32x32.png',
      },
      accountCenter: {
        desktop: {
          position: 'bottomRight',
          enabled: true,
          minimal: false,
        },
        mobile: {
          position: 'bottomRight',
          enabled: true,
          minimal: false,
        },
      },
    });
  }
};

interface WalletConnectProviderProps {
  children: ReactNode;
}

const WalletConnectProvider: FC<WalletConnectProviderProps> = ({ children }) => {
  const [web3onboard, setWeb3Onboard] = useState<any>(null);
  const [chains] = useChainsData((state) => [state.chainsData, state.setData], shallow);

  useEffect(() => {
    setWeb3Onboard(initWalletConnect(chains));
  }, [chains]);

  if (!web3onboard) {
    return null;
  }

  return <Web3OnboardProvider web3Onboard={web3onboard}>{children}</Web3OnboardProvider>;
};

export default WalletConnectProvider;
