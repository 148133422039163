import { entries, find, fromPairs, groupBy, map } from 'lodash';
import { useMemo, useState } from 'react';
import { FiltersDataType } from './types';

export const useFilters = (data: FiltersDataType[]) => {
  const [filtersData, setFiltersData] = useState<FiltersDataType[]>(data);

  const groupedSelectedFiltersByKey = useMemo(() => {
    return fromPairs(
      map(
        entries(
          groupBy(
            map(filtersData, (dataItem) => {
              const selectedOption = find(dataItem?.options, {
                selected: true,
              });
              return {
                key: dataItem.key,
                value: selectedOption,
              };
            }),
            'key',
          ),
        ),
        ([key, value]) => {
          return [key, value[0].value];
        },
      ),
    );
  }, [filtersData]);

  return {
    filtersData,
    groupedSelectedFiltersByKey,
    setFiltersData,
  };
};
